import "./App.css";
import About from "./components/About";
import Contact from "./components/Contact";
import Header from "./components/Header";
import Skills from "./components/Skills";
import TetrisWrapper from "./components/TetrisWrapper";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  databaseUrl: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <div className="App">
      <Header></Header>

      <About></About>

      <Contact></Contact>

      <Skills></Skills>

      <div id="tetrisAnchor" className="tetrisAnchor"></div>
      <div className="tetris">
        <TetrisWrapper></TetrisWrapper>
      </div>

      <div className="spacer"></div>
      
      <div className="thanks">Thanks for checking out my site 😀</div>
    </div>
  );
}

export default App;

import "./Tetris.css";
import Tetris from "react-tetris";

function TetrisWrapper() {
  return (
    <div className="innerTetris">
      <Tetris
        keyboardControls={{
          // Default values shown here. These will be used if no
          // `keyboardControls` prop is provided.
          down: "MOVE_DOWN",
          left: "MOVE_LEFT",
          right: "MOVE_RIGHT",
          space: "HARD_DROP",
          z: "FLIP_COUNTERCLOCKWISE",
          x: "FLIP_CLOCKWISE",
          up: "FLIP_CLOCKWISE",
          p: "TOGGLE_PAUSE",
          c: "HOLD",
          shift: "HOLD",
        }}
      >
        {({
          HeldPiece,
          Gameboard,
          PieceQueue,
          points,
          linesCleared,
          state,
          controller,
        }) => (
          <div>
            <div className="points">
              <p>Points: {points}</p>
            <h2>Tetris</h2>
              <p>Lines Cleared: {linesCleared}</p>
            </div>
            <div className="boardSections">
              <div className="heldPiece">
                <HeldPiece />
              </div>
              {state === "LOST" && (
                <div className="gameOver">
                  <h2>Game Over</h2>
                  <button onClick={controller.restart}>New game</button>
                </div>
              )}
              {state === "PAUSED" && (
                <div className="gameOver">
                  <h2>Paused</h2>
                  <button onClick={controller.resume}>Resume</button>
                </div>
              )}
              <div className="gameBoard">
                <Gameboard />
                <div className="controllerButtons">
                  <div className="column">
                    <button className="lightButton"  onClick={controller.hold}>Hold</button>
                    {state === "PLAYING" && (
                      <button className="lightButton" onClick={controller.pause}>⏹︎</button>
                    )}
                    {state === "PAUSED" && (
                      <button className="lightButton" onClick={controller.resume}>⏵︎</button>
                  )}
                  </div>
                  {/* <button className="lightButton underline" onClick={controller.hardDrop}>Drop</button> */}
                  <button className="lightButton" onClick={controller.moveLeft}>←</button>
                  <button className="lightButton" onClick={controller.moveRight}>→</button>
                  <button className="lightButton" onClick={controller.moveDown}>↓</button>
                  <button className="lightButton" onClick={controller.flipCounterclockwise}>↶</button>
                  <button className="lightButton" onClick={controller.flipClockwise}>↷</button>
                </div>
              </div>
              <div className="pieceQueue">
                <PieceQueue />
              </div>
            </div>
          </div>
        )}
      </Tetris>
    </div>
  );
}

export default TetrisWrapper;
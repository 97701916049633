import "./Contact.css";

function Contact(props) {
  return (
    <div className="contact">
      <h2>Contact</h2>
      <div className="contactSection">
        <a className="link" href="https://www.linkedin.com/in/amynryan/?original_referer=amyryan.dev">
          <i class="devicon-linkedin-plain-wordmark"></i>
        </a>
      </div>
    </div>
  );
}

export default Contact;

import "./About.css";
import MsPacMan from "../images/ms-pac-man.jpg";

const imageStyle = {
  maxWidth: 300,
};

function About(props) {
  return (
    <div className="about">
      {/* <h2>About</h2> */}
      <div className="aboutSection">
        <img
          src={MsPacMan}
          style={imageStyle}
          alt="Senior Full Stack Developer dressed as Ms. PacMan for Halloween"
        ></img>
        <p>
          I am a senior full stack developer with a Masters in computer science.
        </p>
        <p>
          When I started college at the University of Nevada, Las Vegas, I
          thought that I wanted to study mathematics. It only took one semester
          and one course in computer science to fall in love with programming.
          The above photo was taken 11 years ago at my first "real programming
          job" at Shufflemaster. Since then, I have gone from Software Engineer
          to Web Developer, Senior Full Stack Engineer, and finally Senior
          Engineer II, Full Stack. I am looking for a new opportunity creating
          and supporting a product that I am passionate about!
        </p>
      </div>
    </div>
  );
}

export default About;

import HamburgerDrawer from "react-hamburger-drawer";
import "./HamburgerDrawer.css";
import "./Header.css";
const closeHamburgerMenu = () => {
  const menuButton = document.getElementById("HamburgerDrawer-toggle");
  if (menuButton.checked) {
    menuButton.click();
  }
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  closeHamburgerMenu();
};

const scrollToTetris = () => {
  const tetrisAnchor = document.querySelector('#tetrisAnchor');
  tetrisAnchor.scrollIntoView({ behavior: 'smooth'})

  closeHamburgerMenu();
};

export default function Menus() {
  return (
    <header className="App-header">
      <div className="menu" position="fixed">
        <ul className="list">
          <li>
            <button onClick={scrollToTop} className="link">
              Home
            </button>
          </li>
          <li>
            <button onClick={scrollToTetris} className="link">
              Tetris
            </button>
          </li>
        </ul>
      </div>
      <HamburgerDrawer>
        <ul>
          <li>
            <button onClick={scrollToTop} className="link">
              Home
            </button>
          </li>
          <li>
            <button onClick={scrollToTetris} className="link">
              Tetris
            </button>
          </li>
        </ul>
      </HamburgerDrawer>

      <h1 className="title">Amy Ryan</h1>
    </header>
  );
}

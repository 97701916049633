import "./Skills.css";

function Skills(props) {
  return (
    <div className="skills">
      <h2>Skills</h2>
      <div className="skillIcons">
        {/* Include icons from https://devicon.dev/ */}
        <i class="devicon-php-plain"></i>

        <i class="devicon-mysql-plain-wordmark"></i>

        <i class="devicon-html5-plain-wordmark"></i>

        <i class="devicon-react-original-wordmark"></i>

        <i class="devicon-javascript-plain"></i>

        <i class="devicon-symfony-original-wordmark"></i>

        <i class="devicon-css3-plain-wordmark"></i>

        <i class="devicon-git-plain-wordmark"></i>

        <i class="devicon-bitbucket-original-wordmark"></i>

        <i class="devicon-jira-plain-wordmark"></i>

        <i class="devicon-confluence-original-wordmark"></i>

        <i class="devicon-slack-plain-wordmark"></i>

        <i class="devicon-phpstorm-plain-wordmark"></i>

        <i class="devicon-visualstudio-plain-wordmark"></i>

        <i class="devicon-firebase-plain-wordmark"></i>

        <i class="devicon-terraform-plain-wordmark"></i>

        <i class="devicon-python-plain-wordmark"></i>

        <i class="devicon-wordpress-plain-wordmark"></i>

        <i class="devicon-couchdb-plain-wordmark"></i>

        <i class="devicon-windows8-original"></i>

      </div>
    </div>
  );
}

export default Skills;
